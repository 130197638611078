<template>
    <v-card flat>
        <v-card-text class="pb-0 mb-0">
            <v-row style="border-bottom: 2px solid #4E87DD;">
                <v-col cols="4" style="color:#4E87DD; font: normal normal bold 18px/25px Apple SD Gothic Neo;">회원정보 입력</v-col>
                <v-col style="color:#555555; font: normal normal 600 14px/25px Apple SD Gothic Neo;" class="text-right">계정정보와 업체정보를 입력해 주세요.</v-col>
            </v-row>
        </v-card-text>
        <v-card-text class="pt-0">
            <v-row class="mt-2">
                <v-col cols="3" style="font: normal normal normal 18px/25px Apple SD Gothic Neo; padding-top:20px;">아이디</v-col>
                <v-col cols="7">
                    <v-text-field dense outlined hide-details :error="formStatus['admin_user_id'].err" :ref="'admin_user_id'" placeholder="4~12자 영문, 영문+숫자로 입력해 주세요" v-model="formData.admin_user_id"
                                  @keyup="checkChanged('admin_user_id')" @change="checkChanged('admin_user_id', true)">
                        <template v-slot:append v-if="formStatus['admin_user_id'].err_msg">
                            <div style="color:#FF4D4D; font-size:13px; padding-top:5px;">
                                {{ formStatus['admin_user_id'].err_msg }}
                            </div>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="2">
                    <v-btn color="#CCCCCC" width="94px" dark elevation="0" style="font: normal normal 600 16px/25px Apple SD Gothic Neo;" v-if="adminUserIdDupChecked">체크완료</v-btn>
                    <v-btn color="#4E87DD" width="94px" dark elevation="0" style="font: normal normal 600 16px/25px Apple SD Gothic Neo;" @click="checkUserIdDup"
                           v-else-if="formData.admin_user_id && !adminUserIdDupChecked">중복체크
                    </v-btn>
                    <v-btn color="#CCCCCC" width="94px" dark elevation="0" style="font: normal normal 600 16px/25px Apple SD Gothic Neo;" v-else>중복체크</v-btn>
                </v-col>
            </v-row>
            <v-divider class="mt-2 mb-n2"></v-divider>
            <v-row class="mt-1">
                <v-col cols="3" style="font: normal normal normal 18px/25px Apple SD Gothic Neo; padding-top:20px;">비밀번호</v-col>
                <v-col cols="7">
                    <v-text-field type="password" dense outlined hide-details :error="formStatus['admin_user_password'].err" :ref="'admin_user_password'" placeholder="8~20자로 입력해 주세요"
                                  v-model="formData.admin_user_password" @keyup="checkChanged('admin_user_password')" @change="checkChanged('admin_user_password', true)">
                        <template v-slot:append v-if="formStatus['admin_user_password'].err_msg">
                            <div style="color:#FF4D4D; font-size:13px; padding-top:5px;">
                                {{ formStatus['admin_user_password'].err_msg }}
                            </div>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-divider class="mt-2 mb-n2"></v-divider>
            <v-row class="mt-1">
                <v-col cols="3" style="font: normal normal normal 18px/25px Apple SD Gothic Neo; padding-top:20px;">비밀번호 확인</v-col>
                <v-col cols="7">
                    <v-text-field type="password" dense outlined hide-details :error="formStatus['admin_user_password_confirm'].err" :ref="'admin_user_password_confirm'" placeholder="8~20자로 입력해 주세요"
                                  v-model="formData.admin_user_password_confirm" @keyup="checkChanged('admin_user_password_confirm')" @change="checkChanged('admin_user_password_confirm', true)">
                        <template v-slot:append v-if="formStatus['admin_user_password_confirm'].err_msg">
                            <div style="color:#FF4D4D; font-size:13px; padding-top:5px;">
                                {{ formStatus['admin_user_password_confirm'].err_msg }}
                            </div>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-divider class="mt-2 mb-n2"></v-divider>
            <v-row class="mt-1">
                <v-col cols="3" style="font: normal normal normal 18px/25px Apple SD Gothic Neo; padding-top:20px;">관리자 휴대폰번호</v-col>
                <v-col cols="7">
                    <v-text-field
                        dense
                        outlined
                        hide-details
                        :value="joinFormStep01.user_mdn | phoneNum"
                        disabled
                        class="grey lighten-2"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-divider class="mt-2 mb-n2"></v-divider>
            <v-row class="mt-1">
                <v-col cols="3" style="font: normal normal normal 18px/25px Apple SD Gothic Neo; padding-top:20px;">E-mail</v-col>
                <v-col cols="7">
                    <v-text-field dense outlined hide-details :error="formStatus['admin_user_email'].err" :ref="'admin_user_email'" placeholder="E-mail 주소를 입력해 주세요" v-model="formData.admin_user_email"
                                  @keyup="checkChanged('admin_user_email')" @change="checkChanged('admin_user_email', true)">
                        <template v-slot:append v-if="formStatus['admin_user_email'].err_msg">
                            <div style="color:#FF4D4D; font-size:13px; padding-top:5px;">
                                {{ formStatus['admin_user_email'].err_msg }}
                            </div>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-divider class="mt-2 mb-n2"></v-divider>
            <template v-if="joinFormStep01.invited_yn === 'N'">
                <v-row class="mt-1">
                    <v-col cols="3" style="font: normal normal normal 18px/25px Apple SD Gothic Neo; padding-top:20px;">업체명</v-col>
                    <v-col cols="7">
                        <v-text-field dense outlined hide-details :error="formStatus['company_name'].err" :ref="'company_name'" placeholder="업체명을 입력해 주세요" v-model="formData.company_name"
                                      @keyup="checkChanged('company_name')" @change="checkChanged('company_name', true)">
                            <template v-slot:append v-if="formStatus['company_name'].err_msg">
                                <div style="color:#FF4D4D; font-size:13px; padding-top:5px;">
                                    {{ formStatus['company_name'].err_msg }}
                                </div>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-divider class="mt-2 mb-n2"></v-divider>
                <v-row class="mt-1">
                    <v-col cols="3" style="font: normal normal normal 18px/25px Apple SD Gothic Neo; padding-top:20px;">업종</v-col>
                    <v-col cols="7">
                        <v-select
                            outlined
                            dense
                            hide-details
                            placeholder="업종을 선택해주세요."
                            v-model="formData.category_code"
                            :items="selCompanyCategories"
                            item-value="val"
                            item-text="txt"
                            @change="checkChanged('category_code')"
                        >
                            <template v-slot:append v-if="formStatus['category_code'].err_msg">
                                <div style="color:#FF4D4D; font-size:13px; padding-top:5px;">
                                    {{ formStatus['category_code'].err_msg }}
                                </div>
                            </template>
                        </v-select>
                        <v-text-field
                            v-if="dispCategoryEtcInput"
                            class="mt-1"
                            dense
                            outlined
                            hide-details
                            :error="formStatus['category_detail'].err"
                            :ref="'category_detail'"
                            placeholder="업종을 입력해 주세요"
                            v-model="formData.category_detail"
                            @keyup="checkChanged('category_detail')" @change="checkChanged('category_detail', true)">
                            <template v-slot:append v-if="formStatus['category_detail'].err_msg">
                                <div style="color:#FF4D4D; font-size:13px; padding-top:5px;">
                                    {{ formStatus['category_detail'].err_msg }}
                                </div>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-divider class="mt-2 mb-n2"></v-divider>
                <v-row class="mt-1">
                    <v-col cols="3" style="font: normal normal normal 18px/25px Apple SD Gothic Neo; padding-top:20px;">사업자등록증</v-col>
                    <v-col cols="7">
                        <image-input-card v-model="tmpImage" @input="uploadTmpImage" v-if="!formData.corp_reg_img_url" img-type="img_corp" ref="repImg" class="ma-0 pa-0">
                            <div slot="activator">
                                <v-text-field dense outlined hide-details :error="formStatus['corp_reg_img_url'].err" disabled placeholder="이미지 업로드" background-color="grey lighten-3">
                                    <template v-slot:append v-if="formStatus['corp_reg_img_url'].err_msg">
                                        <div style="color:#FF4D4D; font-size:13px; padding-top:5px;">
                                            {{ formStatus['corp_reg_img_url'].err_msg }}
                                        </div>
                                    </template>
                                </v-text-field>
                            </div>
                        </image-input-card>
                        <template v-else>
                            <v-text-field
                                outlined
                                dense
                                hide-details
                                v-model="formData.corp_reg_img_file_name"
                                append-icon="mdi-close"
                                @click:append="deleteImage('img_corp')"
                                background-color="grey lighten-3"
                            >
                                <template v-slot:append-outer class="align-center">
                                    <v-btn dark color="blue" elevation="0" style="margin-top: -5px; margin-bottom: -2px; font: normal normal 600 16px/25px Apple SD Gothic Neo;" v-on:click="openImgPopup('img_corp', 'img')">미리보기</v-btn>
                                </template>
                            </v-text-field>
                        </template>
                    </v-col>
                </v-row>
                <v-divider class="mt-2"></v-divider>
                <v-row class="mt-n1">
                    <v-col cols="3" style="font: normal normal normal 18px/25px Apple SD Gothic Neo; padding-top:20px;">대표자신분증</v-col>
                    <v-col cols="7">
                        <image-input-card @input="uploadTmpImage" v-if="!formData.img_req_1" img-type="img_req_1" allow-type="image.*">
                            <div slot="activator">
                                <v-text-field dense outlined hide-details :error="formStatus['img_req_1'].err" disabled placeholder="이미지 업로드" background-color="grey lighten-3">
                                    <template v-slot:append v-if="formStatus['img_req_1'].err_msg">
                                        <div style="color:#FF4D4D; font-size:13px; padding-top:5px;">
                                            {{ formStatus['img_req_1'].err_msg }}
                                        </div>
                                    </template>
                                </v-text-field>
                            </div>
                        </image-input-card>
                        <template v-else>
                            <v-text-field
                                outlined
                                dense
                                hide-details
                                v-model="formData.img_req_1_file_name"
                                append-icon="mdi-close"
                                @click:append="deleteImage('img_req_1')"
                                background-color="grey lighten-3"
                            >
                                <template v-slot:append-outer class="align-center">
                                    <v-btn dark color="blue" elevation="0" style="margin-top: -5px; margin-bottom: -2px; font: normal normal 600 16px/25px Apple SD Gothic Neo;" v-on:click="openImgPopup('img_req_1', 'link')">미리보기</v-btn>
                                </template>
                            </v-text-field>
                        </template>
                    </v-col>
                </v-row>
                <v-divider class="mt-2"></v-divider>
                <v-row class="mt-n1">
                    <v-col cols="3" style="font: normal normal normal 18px/25px Apple SD Gothic Neo; padding-top:20px;">대리인신분증</v-col>
                    <v-col cols="7">
                        <image-input-card @input="uploadTmpImage" v-if="!formData.img_req_2" img-type="img_req_2" allow-type="image.*">
                            <div slot="activator">
                                <v-text-field dense outlined hide-details :error="formStatus['img_req_2'].err" disabled placeholder="이미지 업로드" background-color="grey lighten-3">
                                    <template v-slot:append v-if="formStatus['img_req_2'].err_msg">
                                        <div style="color:#FF4D4D; font-size:13px; padding-top:5px;">
                                            {{ formStatus['img_req_2'].err_msg }}
                                        </div>
                                    </template>
                                </v-text-field>
                            </div>
                        </image-input-card>
                        <template v-else>
                            <v-text-field
                                outlined
                                dense
                                hide-details
                                v-model="formData.img_req_2_file_name"
                                append-icon="mdi-close"
                                @click:append="deleteImage('img_req_2')"
                                background-color="grey lighten-3"
                            >
                                <template v-slot:append-outer class="align-center">
                                    <v-btn dark color="blue" elevation="0" style="margin-top: -5px; margin-bottom: -2px; font: normal normal 600 16px/25px Apple SD Gothic Neo;" v-on:click="openImgPopup('img_req_2', 'link')">미리보기</v-btn>
                                </template>
                            </v-text-field>
                        </template>
                    </v-col>
                </v-row>
                <v-divider class="mt-2"></v-divider>
            </template>
        </v-card-text>

        <template  v-if="joinFormStep01.invited_yn === 'N'">
            <v-card-text class="pb-0 mb-0">
                <v-row style="border-bottom: 2px solid #4E87DD;">
                    <v-col cols="4" style="color:#4E87DD; font: normal normal bold 18px/25px Apple SD Gothic Neo;">사용용도 입력</v-col>
                    <v-col style="color:#555555; font: normal normal 600 14px/25px Apple SD Gothic Neo;" class="text-right">귀사가 제공하는 서비스 및 '콜'서비스 사용 용도를 입력해주세요.</v-col>
                </v-row>
            </v-card-text>
            <v-row class="mt-2">
                <v-col>
                    <v-text-field dense outlined hide-details :error="formStatus['join_reason'].err" :ref="'join_reason'" placeholder="예) 전자제품 수리 서비스 제공, 제품 수리상태 문의와 예약관리 문의 응대"
                                  v-model="formData.join_reason" @keyup="checkChanged('join_reason')" @change="checkChanged('join_reason', true)">
                        <template v-slot:append v-if="formStatus['join_reason'].err_msg">
                            <div style="color:#FF4D4D; font-size:13px; padding-top:5px;">
                                {{ formStatus['join_reason'].err_msg }}
                            </div>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
        </template>

        <v-card-text class="mt-5">
            <v-row style="border-bottom: 2px solid #4E87DD;">
                <v-col cols="4" style="color:#4E87DD; font: normal normal bold 18px/37px Apple SD Gothic Neo;" >약관동의</v-col>
                <v-col style="color:#555555; font: normal normal 600 14px/37px Apple SD Gothic Neo;" class="text-right">모든 약관에 동의하셔야 회원가입이 가능합니다.</v-col>
            </v-row>
        </v-card-text>
        <v-card-text class="pt-0">
            <v-row class="mt-n3">
                <v-col>
                    <v-checkbox
                        label="전체동의"
                        v-model="formData.agree_all"
                        true-value="Y"
                        false-value="N"
                        class="text-h6"
                        @change="checkAgreeAll"
                    >
                    </v-checkbox>
                </v-col>
            </v-row>
            <v-row class="mt-n13">
                <v-col cols="8">
                    <v-checkbox
                        v-model="formData.agree_service_yn"
                        true-value="Y"
                        false-value="N"
                        class="ml-10"
                        @change="checkAgreeDivide('agree_service_yn')"
                        :error="formData['agree_service_yn'].err"
                    >
                        <template v-slot:label>
                            <div>
                                <span style="font: normal normal normal 16px/37px Apple SD Gothic Neo;">콜 서비스 이용약관 동의</span>
                                <span class="red--text font-weight-bold" style="font: normal normal normal 15px/37px Apple SD Gothic Neo;"> (필수)</span>
                            </div>
                            <span v-if="formStatus['agree_service_yn'].err_msg" style="color:#FF4D4D; font-size:13px; margin-left: 43px;">{{ formStatus['agree_service_yn'].err_msg }}</span>
                        </template>
                    </v-checkbox>
                </v-col>
                <v-col class="grey--text mt-5">
                    <v-btn text class="grey--text" @click="showAgree('SERVICE')" style="font: normal normal normal 16px/37px Apple SD Gothic Neo;">자세히보기 ></v-btn>
                </v-col>
            </v-row>
            <v-row class="mt-n15">
                <v-col cols="8">
                    <v-checkbox
                        v-model="formData.agree_private_yn"
                        true-value="Y"
                        false-value="N"
                        class="ml-10"
                        @change="checkAgreeDivide('agree_private_yn')"
                    >
                        <template v-slot:label>
                            <div><span style="font: normal normal normal 16px/37px Apple SD Gothic Neo;">개인정보 처리방침</span>
                                <span class="red--text font-weight-bold" style="font: normal normal normal 15px/37px Apple SD Gothic Neo;"> (필수)</span>
                            </div>
                            <span v-if="formStatus['agree_private_yn'].err_msg" style="color:#FF4D4D; font-size:13px; margin-left: 80px;">{{ formStatus['agree_private_yn'].err_msg }}</span>
                        </template>
                    </v-checkbox>
                </v-col>
                <v-col class="grey--text mt-5">
                    <v-btn text class="grey--text" @click="showAgree('PRIVATE')" style="font: normal normal normal 16px/37px Apple SD Gothic Neo;">자세히보기 ></v-btn>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-text class="text-center">
            <v-btn color="#4E87DD" width="200px" height="55px" dark elevation="0" style="font: normal normal bold 20px/37px Apple SD Gothic Neo; opacity: 0.5" @click="doNext" v-if="inActiveBtn">다음</v-btn>
            <v-btn color="#4E87DD" width="200px" height="55px" dark elevation="0" style="font: normal normal bold 20px/37px Apple SD Gothic Neo; opacity: 1" @click="doNext" v-else>다음</v-btn>
        </v-card-text>
        <v-dialog v-model="modal" persistent max-width="650">
            <v-flex v-if="dialogMode === 'img'">
                <img-preview-popup :url="popupImgUrl" v-on:cancel="closePopup" :type="popupImgType"></img-preview-popup>
            </v-flex>
        </v-dialog>
    </v-card>
</template>
<script>
import {mapState, mapGetters} from 'vuex'
import joinApi from '@/api/join'
import config from '@/config'
import NumTextField from "@/components/common/num_text_field.vue";

export default {
    name: 'join_step_02',
    components: {
        NumTextField,
        'imgPreviewPopup': () => import('@/components/svc/wv/popup_img_preview'),
        'imageInputCard': () => import('@/components/svc/wv/tile_image_input'),
    },
    data: () => ({
        selCompanyCategories: [],
        formData: {
            join_tid: '',
            admin_user_id: '',
            admin_user_id_dup_checked_yn: 'N',
            admin_user_password: '',
            admin_user_password_confirm: '',
            admin_user_email: '',
            corp_reg_img_url: '',
            corp_reg_img_type: '',
            corp_reg_img_file_name: '',
            company_name: '',
            category_code: '',
            category_name: '',
            category_detail: '',
            join_reason: '',
            agree_all: 'N',
            agree_service_yn: 'N',
            agree_private_yn: 'N',
            img_req_1: '',
            img_req_1_file_name: '',
            img_req_2: '',
            img_req_2_file_name: ''
        },
        formStatus: {
            'admin_user_id': {
                err: false,
                err_msg: '',
                dup_checked_val: '',
            },
            'admin_user_password': {
                err: false,
                err_msg: '',
            },
            'admin_user_password_confirm': {
                err: false,
                err_msg: '',
            },
            'user_name': {
                err: false,
                err_msg: '',
            },
            'admin_user_email': {
                err: false,
                err_msg: ''
            },
            'corp_reg_num': {
                err: false,
                err_msg: ''
            },
            'company_name': {
                err: false,
                err_msg: ''
            },
            'corp_reg_img_url': {
                err: false,
                err_msg: '',
                img_type: ''
            },
            'category_code': {
                err: false,
                err_msg: ''
            },
            'category_name': {
                err: false,
                err_msg: ''
            },
            'category_detail': {
                err: false,
                err_msg: ''
            },
            'agree_service_yn': {
                err: false,
                err_msg: '',
                desc: '콜 서비스 이용약관 동의'
            },
            'agree_private_yn': {
                err: false,
                err_msg: '',
                desc: '개인정보 처리방침'
            },
            'img_req_1': {
                err: false,
                err_msg: ''
            },
            'img_req_2': {
                err: false,
                err_msg: ''
            },
            'join_reason': {
                err: false,
                err_msg: ''
            }
        },
        tmpImage: null,
        modal: false,
        dialogMode: 'none',
        popupImgUrl: '',
        popupImgType: ''
    }),
    computed: {
        ...mapState('company_category', {
            categoryList: state => state.categoryList
        }),
        ...mapGetters({
            joinTid: 'join/getJoinTid',
            joinFormStep01: 'join/getJoinFormStep01',
            joinFormStep02: 'join/getJoinFormStep02',
        }),
        adminUserIdDupChecked() {
            return (this.formData && this.formData.admin_user_id_dup_checked_yn === 'Y')
        },
        dispCategoryEtcInput() {
            return this.formData.category_code === 'ETC'
        },
        inActiveBtn() {
            return this.checkFormErr() ||
                !this.formData.admin_user_id ||
                !this.adminUserIdDupChecked ||
                !this.formData.admin_user_password ||
                !this.formData.admin_user_password_confirm ||
                !this.formData.admin_user_email ||
                (this.joinFormStep01.invited_yn === 'N' &&
                    (!this.formData.company_name ||
                        !this.formData.corp_reg_img_url ||
                        !this.formData.img_req_1 ||
                        !this.formData.category_code ||
                        !this.formData.join_reason ||
                        this.formData.join_reason.length > 200 ||
                        (this.dispCategoryEtcInput && this.formData.category_detail.trim().length === 0)
                    )
                ) ||
                this.formData.agree_service_yn === 'N' ||
                this.formData.agree_private_yn === 'N'
        },
        req1ImgKey() {
            return this.formData.img_req_1 && this.formData.img_req_1.trim().length !== 0 ? config.API_BASE_URL + '/join/preview_img/' + this.formData.join_tid + '/' + this.formData.img_req_1 : ''
        },
        req2ImgKey() {
            return this.formData.img_req_2 && this.formData.img_req_2.trim().length !== 0 ? config.API_BASE_URL + '/join/preview_img/' + this.formData.join_tid + '/' + this.formData.img_req_2 : ''
        }
    },
    created() {
        let ctx = this

        let paramJoinTid = this.$route.params.join_tid

        if (!this.joinFormStep01 || Object.keys(this.joinFormStep01).length === 0 || !this.joinFormStep01.join_tid || this.joinFormStep01.join_tid !== paramJoinTid) {
            this.$store.dispatch('openAlert', {
                'message': '잘못된 접근입니다',
                closeCb: (va) => {
                    va.$router.push('/join/step01')
                },
                va: this
            })
        }

        this.formData.join_tid = this.joinFormStep01.join_tid

        if (!this.categoryList || this.categoryList.length === 0) {
            this.$store.dispatch('company_category/getCategoryList', {
                param: {},
                scb: () => {
                    ctx.initCompanyCategories()
                },
                fcb: (err) => {
                    ctx.$store.dispatch('openAlert', {'message': err})
                }
            }, ctx)
        } else {
            this.initCompanyCategories()
        }
    },
    mounted() {
        this.$store.commit('join/setJoinStep', 2)

        if (this.joinFormStep02 && Object.keys(this.joinFormStep02).length !== 0) {
            this.formData = Object.assign({}, this.joinFormStep02)
            this.formStatus['corp_reg_img_url'].img_type = this.formData.corp_reg_img_type
        } else {
            this.$refs.admin_user_id.focus()
        }
    },
    methods: {
        initCompanyCategories() {
            let ctx = this
            if (this.categoryList && this.categoryList.length !== 0) {
                this.selCompanyCategories.splice(0, this.selCompanyCategories.length)

                this.categoryList.forEach(item => {
                    ctx.selCompanyCategories.push({'txt': item.category_name, 'val': item.category_code})
                })
            }
        },
        /*goPrev() {
            this.$router.push('/join/step01')
            /!*this.$store.dispatch('openConfirm', {
                'message': '이전 화면으로 돌아가시겠습니까?',
                'okCb': (va) => {
                    va.$router.push('/join/step01')
                },
                'va': this
            })*!/
        },*/
        handleErrorSubmit(err) {
            let msg = '회원 가입 처리를 실패 했습니다'
            let subMsg = ''

            if (err === 'NO_USER' || err === 'NO_ADMIN_USER') {
                subMsg = '사용자 정보를 찾을 수 없습니다'
            } else if (err === 'NO_COMPANY') {
                subMsg = '업체 정보를 찾을 수 없습니다'
            } else if (err === 'ALREADY_USER' || err === 'ALREADY_ADMIN_USER') {
                subMsg = '이미 회원으로 등록되어 있습니다'
            } else if (err === 'NO_TRAN') {
                msg = '인증시간이 만료되었습니다. 다시 인증해주세요.'
            } else if (err === 'DUPLICATED_ADMIN_USER_ID') {
                msg = '입력하신 ID는 사용 불가능합니다'
            }

            this.$store.dispatch('openAlert', {
                'message': msg,
                'sub_message': subMsg,
                'va': this,
                'closeCb': (va) => {
                    if (err === 'NO_TRAN') {
                        va.$router.push('/join/step01')
                    } else if (err === 'DUPLICATED_ADMIN_USER_ID') {
                        va.updateErrStatus('admin_user_id', true, '아이디를 입력해 주세요')
                        va.formData.admin_user_id_dup_checked_yn = 'N'
                        va.formStatus.admin_user_id.dup_checked_val = ''
                    }
                }
            })
        },
        validateForm() {
            let succ = true

            if (this.checkFormErr()) {
                succ = false
            }

            if (!this.formData.admin_user_id) {
                this.updateErrStatus('admin_user_id', true, '아이디를 입력해 주세요')
                succ = false
            } else if (!this.adminUserIdDupChecked) {
                this.updateErrStatus('admin_user_id', true, '중복체크 해주세요')
                succ = false
            }

            if (!this.formData.admin_user_password) {
                this.updateErrStatus('admin_user_password', true, '비밀번호를 입력해 주세요')
                succ = false
            }
            if (!this.formData.admin_user_password_confirm) {
                this.updateErrStatus('admin_user_password_confirm', true, '비밀번호 확인을 입력해 주세요')
                succ = false
            }
            if (!this.formData.admin_user_email) {
                this.updateErrStatus('admin_user_email', true, 'E-mail을 입력해 주세요')
                succ = false
            }

            if (this.joinFormStep01.invited_yn === 'N') {
                if (!this.formData.company_name) {
                    this.updateErrStatus('company_name', true, '업체명을 입력해 주세요')
                    succ = false
                }
                if (!this.formData.corp_reg_img_url) {
                    this.updateErrStatus('corp_reg_img_url', true, '사업자등록증 이미지를 업로드 해주세요')
                    succ = false
                }
                if (!this.formData.category_code) {
                    this.updateErrStatus('category_code', true, '업종을 선택해 주세요.')
                    succ = false
                }
                if (this.dispCategoryEtcInput && this.formData.category_detail.trim().length === 0) {
                    this.updateErrStatus('category_detail', true, '업종을 입력해 주세요.')
                    succ = false
                }
                if (!this.formData.img_req_1) {
                    this.updateErrStatus('img_req_1', true, '대표자 신분증을 업로드 해주세요.')
                    succ = false
                }
                if (!this.formData.join_reason) {
                    this.updateErrStatus('join_reason', true, '사용 용도를 입력해주세요.')
                    succ = false
                } else if (this.formData.join_reason.length > 200) {
                    this.updateErrStatus('join_reason', true, '최대 200자까지 입력 가능합니다.')
                    succ = false
                }
            }


            if (this.formData.agree_service_yn === 'N') {
                this.updateErrStatus('agree_service_yn', true, '필수 체크 사항입니다')
                succ = false
            }
            if (this.formData.agree_private_yn === 'N') {
                this.updateErrStatus('agree_private_yn', true, '필수 체크 사항입니다')
                succ = false
            }

            return succ

        },
        doNext() {
            if (!this.validateForm()) {
                return
            }

            if (this.joinFormStep01.invited_yn === 'N') {
                let category = this.selCompanyCategories.find((option) => option.val === this.formData.category_code)
                this.formData.category_name = category.txt

                if (this.formData.category_code !== 'ETC') {
                    this.formData.category_detail = ''
                }

                this.formData.corp_reg_img_type = this.formStatus.corp_reg_img_url.img_type
            }

            if (this.joinFormStep01.invited_yn === 'Y') {
                joinApi.submitInvited(this.formData, (authSession, va) => {
                    va.$store.dispatch('join/init', {})
                    va.$store.commit('login/setLogin', authSession)

                    va.$store.dispatch('openAlert', {
                        'message': '관리자 등록이 완료되었습니다.\r\n메인 페이지로 이동합니다.',
                        'va': va,
                        'closeCb': (va) => {
                            va.$router.push('/dashboard')
                        }
                    })
                }, (err, va) => {
                    va.handleErrorSubmit(err)
                }, this)

            } else {
                joinApi.submitInfo(this.formData, (product, va) => {
                    va.$store.commit('join/setJoinFormStep02', va.formData)
                    va.$store.commit('join/setProduct', product)
                    va.$router.push(`/join/step03/${va.joinTid}`)
                }, (err, va) => {
                    va.handleErrorSubmit(err)
                }, this)
            }

            /*this.$store.dispatch('openConfirm', {
                message: '입력된 내용을 진행하시겠습니까?',
                va: this,
                okCb: function (va) {
                    if (va.joinFormStep01.invited_yn === "Y") {
                        joinApi.submitInvited(va.formData, (authSession, va) => {
                            va.$store.dispatch('join/init', {})
                            va.$store.commit('login/setLogin', authSession)

                            va.$store.dispatch('openAlert', {
                                'message': '관리자 등록이 완료되었습니다.\r\n메인 페이지로 이동합니다.',
                                'va': va,
                                'closeCb': (va) => {
                                    va.$router.push('/dashboard')
                                }
                            })
                        }, (err, va) => {
                            va.handleErrorSubmit(err)
                        }, ctx)

                    } else {
                        joinApi.submitInfo(va.formData, (product, va) => {
                            va.$store.commit('join/setJoinFormStep02', va.formData)
                            va.$store.commit('join/setProduct', product)
                            va.$router.push(`/join/step03/${va.joinTid}`)
                        }, (err, va) => {
                            va.handleErrorSubmit(err)
                        }, ctx)
                    }
                }
            })*/
        },
        checkUserIdDup() {
            let adminUserId = this.formData.admin_user_id

            if (!adminUserId) {
                this.updateErrStatus('admin_user_id', true, '아이디를 입력해 주세요')
                return
            }
            let regType = /^[A-Za-z][A-Za-z0-9+]{3,11}$/;
            if (!regType.test(adminUserId)) {
                this.updateErrStatus('admin_user_id', true, '4~12자 영문, 영문+숫자로 입력해 주세요')
                return
            }

            let scb = (dupType) => {
                if (dupType === 'NONE') {
                    let va = this
                    this.formData.admin_user_id_dup_checked_yn = 'Y'
                    this.formStatus.admin_user_id.dup_checked_val = adminUserId
                    this.updateErrStatus('admin_user_id', false, '')
                    this.$store.dispatch('openAlert', {
                        message: '입력하신 ID는 사용 가능합니다', va: this, closeCb: function () {
                            va.$refs.admin_user_password.focus()
                        }
                    })
                } else {
                    this.$store.dispatch('openAlert', {message: '입력하신 ID는 사용 불가능합니다'})
                }
            }
            let fcb = () => {
                this.updateErrStatus('admin_user_id', true, '사용자 ID 중복 체크를 실패 했습니다.')
            }

            joinApi.checkId({admin_user_id: adminUserId}, scb, fcb, this)
        },
        checkChanged(t, focusOutFlag) {
            let val = this.formData[t]
            let st = this.formStatus[t]
            let numOnlyType = /^[0-9+]+$/;

            if (t === 'admin_user_id') {
                if (st) {
                    if (st.err) {
                        st.err = false
                        st.err_msg = ''
                    } else if (st.dup_checked_val !== val) {
                        this.formData.admin_user_id_dup_checked_yn = 'N'
                        st.dup_checked_val = ''
                    }
                }
            } else if (t === 'admin_user_password') {
                if (!val || val.length < 8 || val.length > 20) {
                    this.updateErrStatus(t, true, '8~20자로 입력해 주세요')
                } else {
                    this.updateErrStatus(t, false, '')
                }
            } else if (t === 'admin_user_password_confirm') {
                let refVal = this.formData.admin_user_password

                if (refVal !== '' && refVal === val) {
                    this.updateErrStatus(t, false, '')
                } else if (val === '') {
                    this.updateErrStatus(t, false, '')
                } else {
                    this.updateErrStatus(t, true, '비밀번호가 일치하지 않습니다')
                }
            } else if (t === 'admin_user_email') {
                if (st && (focusOutFlag || st.err)) {
                    let regType = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

                    if (val && !regType.test(val)) {
                        this.updateErrStatus(t, true, 'E-mail 주소 형식이 잘못되었습니다')
                    } else {
                        this.updateErrStatus(t, false, '')
                    }
                }
            } else if (t === 'corp_reg_num') {
                if (val && !numOnlyType.test(val)) {
                    this.updateErrStatus(t, true, "'-' 없이 숫자만 입력해 주세요")
                } else {
                    this.updateErrStatus(t, false, '')
                }
            } else if (t === 'join_reason') {
                if (val.length > 200) {
                    this.updateErrStatus(t, true, '최대 200자까지 입력 가능합니다')
                    this.formData.join_reason = this.formData.join_reason.substring(0, 200)
                } else {
                    this.updateErrStatus(t, false, '')
                }
            } else {
                if (st) {
                    if (st.err) {
                        st.err = false
                        st.err_msg = ''
                    }
                }
            }
        },
        checkFormErr() {
            for (let k in this.formStatus) {
                if (this.formStatus[k].err) {
                    return true
                }
            }

            return false
        },
        updateErrStatus(target, err, errMsg) {
            let status = this.formStatus[target]
            let ref = null

            if (target === 'admin_user_id') {
                ref = this.$refs.admin_user_id
            }

            if (!errMsg) {
                errMsg = ''
            }

            if (status) {
                status.err = err
                status.err_msg = errMsg
                if (err && ref) {
                    ref.focus()
                }
            }
        },
        uploadTmpImage(payload) {
            let va = this
            let param = {}

            param.join_tid = this.formData.join_tid
            param.img_type = payload.imgType
            param.img_file = payload.imageFile

            joinApi.uploadImage(param, (data) => {

                if (payload.imgType === 'img_corp' && data.content_url) {
                    va.formData.corp_reg_img_url = data.content_url
                    va.formData.corp_reg_img_file_name = payload.imageFile.name
                    va.formStatus.corp_reg_img_url.err = false
                    va.formStatus.corp_reg_img_url.err_msg = ''
                    va.formStatus.corp_reg_img_url.img_type = ''
                    if (payload.imageFile.type.match('image.*')) {
                        va.formStatus.corp_reg_img_url.img_type = 'IMG'
                        va.formData.corp_reg_img_type = 'IMG'
                    } else if (payload.imageFile.type.match('application/pdf')) {
                        va.formStatus.corp_reg_img_url.img_type = 'PDF'
                        va.formData.corp_reg_img_type = 'PDF'
                    } else {
                        va.formStatus.corp_reg_img_url.img_type = 'UNKNOWN'
                        va.formData.corp_reg_img_type = 'UNKNOWN'
                    }
                } else if ((payload.imgType === 'img_req_1' || payload.imgType === 'img_req_2') && data.content_key) {
                    if (payload.imgType === 'img_req_1') {
                        va.formData.img_req_1 = data.content_key
                        va.formData.img_req_1_file_name = payload.imageFile.name
                        va.formStatus.img_req_1.err = false
                        va.formStatus.img_req_1.err_msg = ''
                    } else {
                        va.formData.img_req_2 = data.content_key
                        va.formData.img_req_2_file_name = payload.imageFile.name
                        va.formStatus.img_req_2.err = false
                        va.formStatus.img_req_2.err_msg = ''
                    }
                }
            }, (err) => {
                if (va.$noSessionProc(err)) {
                    return
                }
                alert("이미지 등록을 실패 했습니다")
            })
        },
        getImgUrl(imgType) {
            let ret = ''
            if (imgType === 'img_corp') {
                ret = this.formData.corp_reg_img_url
            } else if (imgType === 'img_req_1') {
                ret = this.req1ImgKey
            } else if (imgType === 'img_req_2') {
                ret = this.req2ImgKey
            }

            return ret
        },
        openImgPopup(imgType, viewType) {
            if (imgType) {
                this.popupImgUrl = this.getImgUrl(imgType)
                this.popupImgType = viewType
                this.dialogMode = 'img'
                this.modal = true
            }
        },
        closePopup() {
            this.modal = false
            this.dialogMode = 'NONE'
        },
        deleteImage(type) {
            if (type === 'img_corp') {
                this.tmpImage = null
                this.formData.corp_reg_img_url = ''
                this.formData.corp_reg_img_file_name = ''
            } else if (type === 'img_req_1') {
                this.formData.img_req_1 = ''
                this.formData.img_req_1_file_name = ''
            } else if (type === 'img_req_2') {
                this.formData.img_req_2 = ''
                this.formData.img_req_2_file_name = ''
            }
        },
        checkAgreeAll() {
            let agreeTypes = ['agree_service_yn', 'agree_private_yn']

            agreeTypes.forEach(type => {
                this.formData[type] = this.formData.agree_all

                if (this.formData.agree_all === 'Y') {
                    this.updateErrStatus(type, false, '')
                }
            })
        },
        checkAgreeDivide(agreeType) {
            if (this.formData.agree_service_yn === 'N' || this.formData.agree_private_yn === 'N') {
                this.formData.agree_all = 'N'
            } else if (this.formData.agree_service_yn === 'Y' && this.formData.agree_private_yn === 'Y') {
                this.formData.agree_all = 'Y'
            }

            if (this.formData[agreeType] === 'Y') {
                this.updateErrStatus(agreeType, false, '')
            } else if (this.formData[agreeType] === 'N') {
                this.updateErrStatus(agreeType, true, '필수 체크 사항입니다')
            }
        },
        showAgree(agreeType) {
            this.$store.commit('setAgree', {'type': agreeType, 'isShow': true})
        },
        launchFilePicker() {
            console.log('step02 launchFilePicker....')
            this.$refs.repImg.launchFilePicker()
        }
    }
}
</script>
<style>
</style>


